import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./layout/sidebar/Sidebar";
import Home from "./pages/home/Home";
import Users from "./pages/users/Users";
import Reservations from "./pages/reservations/Reservations";
import Rooms from "./pages/rooms/Rooms";
import Login from "./pages/login/Login";
import { useEffect, useState } from "react";
function App() {
  const [logged , setLogged] = useState(false)

  useEffect(()=> { 
    const token = localStorage.getItem("token")
    if(token) 
    setLogged(true)
  } , [])
  return ( <BrowserRouter>
            

     { !logged ?         <Login />
 :  <div className="App">
        <Sidebar />
        <div className="dashboard_content">
        <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/users" element={<Users />}></Route>
            <Route path="/reservations" element={<Reservations />}></Route>
            <Route path="/rooms" element={<Rooms />}></Route>
        </Routes>
        </div>
        
      </div>
      
      }

    </BrowserRouter>
  );
}

export default App;
