import axios from "axios";
import { store } from "react-notifications-component";

export const BASE = process.env.REACT_APP_API_URL;

export const BASEAPI = process.env.REACT_APP_API_URL;

export const openNotificationWithIcon = (type, title, msg) => {
  try {
    store.addNotification({
      title: title,
      message: msg,
      type: type, // 'default', 'success', 'info', 'warning'
      container: "top-right", // where to position the notifications
      animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
      dismiss: {
        duration: 3000
      }
    });
  } catch (err) {
    console.log(err);
  }
};

const service = axios.create({
  baseURL: BASEAPI,
  headers: {
    "Content-Type": "application/json"
  }
});
//'Authorization': localStorage.getItem('token')

export const postModule = ({ module, data }) => {
  init();
  return service
    .post(module, data)
    .then(function (response) {
      let data = {
        status: response.status,
        successful: true,
        data: response.data
      };
      handleErrors(response.data);
      return data;
    })
    .catch((error) => {
      let unsscess = {
        isErr: true,
        successful: false,
        err: error,
        response: { successful: false }
      };
      if (error.response) {
        if (error.response.status == 502) {
          openNotificationWithIcon(
            "error",
            "technical issue",
            error.response.data
          );
        }
        if (error.response.status == 403) {
          localStorage.removeItem("token");
          window.location.href = "/";
        }
        if (error.response.status == 422) {
          unsscess.response = error.response.data;
          return unsscess;
        }
        if (error.response.status == 409) {
          unsscess.response = error.response.data;
          return unsscess;
        } else {
          return unsscess;
        }
      } else {
        return unsscess;
      }
    });
};

export const getModule = ({ module, data }) => {
  init();
  return service
    .get(module, { data })
    .then(function (response) {
      let data = {
        status: response.status,

        data: response.data
      };

      handleErrors(data);
      return data;
    })
    .catch(function (error, response) {
      let unsscess = {
        isErr: true,
        successful: false,
        err: error,
        response: { successful: false }
      };
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
        }
        if (error.response.status == 403) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          return unsscess;
        }
      } else {
        return unsscess;
      }
    });
};

const init = () => {
  service.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    return config;
  });
  service.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  service.defaults.headers.get["Accepts"] = "application/json";
  service.defaults.headers.post["Content-Type"] =
    "application/json; charset=utf-8";
};

const handleErrors = (data) => {
  if (data.successful == false && data.errorData.Errors != null) {
    let errorData = data.errorData;

    for (let i = 0; i < errorData.Errors.length; i++) {
      let err = errorData.Errors[i];
      openNotificationWithIcon("danger", "Error", err);
    }

    for (let i = 0; i < errorData.Fields.length; i++) {
      let err = errorData.Fields[i];

      for (var j = 0; j < err.Errors.length; j++) {
        openNotificationWithIcon("danger", err.FieldName, err.Errors[j]);
      }
    }
  }
};
