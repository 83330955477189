import React from "react";
import "./Sidebar.css";
import logo from "../../assets/logo.svg";
import { NavLink } from "react-router-dom";
export default function sidebar() {
  return (
    <div className="sidebar">
      <div className="logo">
        <img src={logo} alt="MeTime" />
      </div>
      <ul className="menu">
        <li>
          <NavLink to="/" activeClassName="active">
            <i className="bi bi-house-fill"></i>Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/users" activeClassName="active">
            <i className="bi bi-people-fill"></i>Users
          </NavLink>
        </li>
        <li>
          <NavLink to="/reservations" activeClassName="active">
            <i className="bi bi-bookmarks-fill"></i>Reservations
          </NavLink>
        </li>
        <li>
          <NavLink to="/rooms" activeClassName="active">
            <i className="bi bi-layers-fill"></i>Rooms
          </NavLink>
        </li>
        <li>
          <NavLink to="/login" activeClassName="active">
            <i className="bi bi-box-arrow-left"></i>Logout
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
