import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { getModule, postModule } from "../../utils/apiService";
import Pagination from "react-js-pagination";
import "bootstrap"
import "./Users.css"

export default function Users() {
  const [users, setUsers] = useState([]);
  const [activePage , setActivePage] = useState(1)
  const [max_pages , setMaxPages] = useState(20)
 
  useEffect(() => {
    getModule({module : "/users"}).then(rsp =>{  
      setMaxPages(  (parseInt(rsp.data.count)))
    
    setUsers(rsp.data.data)
    
    })
  }, []);
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber)
    getModule({module : "/users?page=" + pageNumber}).then(rsp =>{  
      setUsers(rsp.data.data)
      
      })   
      // console.log(`active page is ${pageNumber}`);
   // this.setState({activePage: pageNumber});
  }
  return (
    <>
      <div className="content">
        <h2>Users:</h2>
        <table className="table table-striped table-dark scrollView">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>


              <th scope="col">More Details</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item) => {
              return (
                <tr key={item.id}>
                  <th>{item.id}</th>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.role}</td>
                  <td>
                    <button onClick={()=>postModule({module:"users/activate" , data :{id:item.id}})}>
                    Activate
                    </button>
                    
                   </td>


                  <td className="more_user_details">
                    <Link
                      to={`/users/${item.id}`}
                    >
                      <i className="bi bi-box-arrow-up-right"></i>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={20}
          totalItemsCount={max_pages}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
}
