import React, { useEffect, useState } from "react";
import "../../App.css";
import { getModule } from "../../utils/apiService";

export default function Rooms() {
  const [rooms, setRooms] = useState([]);
  const cities = JSON.parse(localStorage.getItem('cities'))

  const getCity = (id) => {
    return cities.filter(c => c.id === id)[0]
  }
  useEffect(() => {
    getModule({module : "rooms"}).then(rsp => setRooms(rsp.data.data))

  }, []);
  return (
    <>
      <div className="content">
        <h2>Rooms:</h2>
        <table className="table table-striped table-dark">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">City</th>
              <th scope="col">Phone Number</th>

              <th scope="col">Address</th>
              <th scope="col">More Details</th>
            </tr>
          </thead>
          <tbody>
            {rooms.map((item) => {
              return (
                <tr key={item.id}>
                  <th>{item.id}</th>
                  <td>{item.name}</td>
                  <td>{getCity(item.cityId).label }</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.address}</td>
                  <td className="more_user_details">
                    <a
                      href={`https://jsonplaceholder.typicode.com/users/${item.id}`}
                      target="_blank"
                    >
                      <i className="bi bi-box-arrow-up-right"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
