import React, { useState } from "react";
import LogoForm from "../../assets/blackLogo.svg";
import { postModule } from "../../utils/apiService";
import "./Login.css";
import { Formik , Field , Form} from 'formik';

export default function Login() {
  const [email , setEmail] = useState()
  const loginHandler = (values) => {
  console.log(values)
    postModule({module:"auth/login" , data : values}).then(rsp => {
      if(rsp.status ===200){
      localStorage.setItem("token" ,rsp.data.accessToken) 
      window.location.href="/"
    }
    } )

  }
  return (
    <div className="login_form">
       <Formik
      initialValues={{
     
        email: '',
        password : ""
      }}
      onSubmit={loginHandler}
    >
      <Form>
        <div className="logo_container">
          <img src={LogoForm} alt="MeTime" />
        </div>
        
        <div className="field_container">
          <label>Email</label>
          <Field
          id="email"
          name="email"
          placeholder="jane@acme.com"
          type="email"
        />        </div>
        <div className="field_container">
          <label>Password</label>
          <Field
          id="password"
          name="password"
          placeholder="password"
          type="password"
        />        </div>
        <button type="submit">Login</button>
      </Form>
      </Formik>
    </div>
  );
}
