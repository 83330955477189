import React, { useEffect, useState } from "react";
import "../../App.css";
import { getModule } from "../../utils/apiService";

export default function Reservations() {
  const [reservations, setReservations] = useState([]);
  
  useEffect(() => {
    
     getModule({module:"reservations"}).then(rsp => setReservations(rsp.data.data.reverse()))
  }, []);
  console.log(reservations)

  return (
    <>
      <div className="content">
        <h2>Reservations:</h2>
        <table className="table table-striped table-dark">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Start</th>
              <th scope="col">Price</th>
              <th scope="col">Room</th>
              <th scope="col">Client </th>
              <th scope="col">PhoneNumber </th>

              <th scope="col">Status</th>

              <th scope="col">More Details</th>
            </tr>
          </thead>
          <tbody>
            {reservations.map((item) => {
              const date_ = new Date(item.startDate)
              return (
                <tr key={item.id}>
                  <th>{item.id}</th>
                  <td>{date_.toDateString() + " " + (date_.getHours()-1)+ ":" + date_.getMinutes()}</td>
                  <td>{item.price}</td>
                  <td>{item.room.name}</td>
                  <td>{item.user.firstName + " " + item.user.lastName}</td>
                  <td>{item.user.phoneNumber }</td>

                  <td>{item.completedAt ? new Date(item.completedAt).toDateString() : null}</td>

                  <td className="more_user_details">
                    <a
                      href=""
                      target="_blank"
                    >
                      <i className="bi bi-box-arrow-up-right"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
